import React, {useState, useRef, Fragment, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {withRouter, useRouteUnmountEffect, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efServiceAssignmentStatusTypes, efTransportationTypes, apmContractPartnerAssignmentChannelTypes, efServiceCaseTypes, efServiceAssignmentTypes, ampVehicleCreationStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ButtonPrimary, Button} from '@ace-de/ui-components';
import {Form, CheckboxGroup, Checkbox, InputField} from '@ace-de/ui-components/form';
import {Icon, arrowDownIcon, arrowUpIcon, linkIcon, InteractiveIcon, editIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import routePaths from '../../routePaths';
import contractPartnerScreenTabs from '../../contract-partners/contractPartnerScreenTabs';
import styles from './CommissioningContractPartnerPanel.module.scss';
import config from '../../config';
import {validateEmail, validateFax} from '../../utils/validation';
import modalIds from '../../modalIds';

const assignmentChannelSetup = {
    [apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]: {
        fieldName: 'email',
        validationMethod: validateEmail,
    },
    [apmContractPartnerAssignmentChannelTypes.FAX]: {
        fieldName: 'faxNo',
        validationMethod: validateFax,
    },
    [apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE]: {
        fieldName: 'phoneNo',
        validationMethod: null,
    },
};

const CommissioningContractPartnerPanel = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_commissioning_tab');
    const translateAssignmentChannel = createTranslateShorthand('global.contract_partner_assignment_channel');
    const {serviceCase, serviceAssignment} = props;
    const {initiateSaveArchiveServiceAssignmentFlow, initiateServiceAssignmentStatusUpdateFlow} = props;
    const {initiateUpdateSAVAssignmentChannels, initiateResendServiceAssignmentFlow, location} = props;
    const {submitContractPartnerAssignmentChannels, submitCreatePDFForm, initiateUpdateACEPartnerFlow} = props;
    const {initialServiceAssignment, attachmentsToSendWithAssignment} = props;
    const [isPhoneToggleOn, setIsPhoneToggleOn] = useState(false);
    const {acePartner: selectedContractPartner} = serviceAssignment;
    const hasDriverAndOwnerData = serviceCase?.vehicle?.driver.type && serviceCase?.vehicle?.owner.type;
    const hasServiceType = serviceCase?.serviceType;
    const [errorMessages, setErrorMessages] = useState({
        [apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]: '',
        [apmContractPartnerAssignmentChannelTypes.FAX]: '',
        [apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE]: '',
    });
    const [formData, setFormData] = useState({
        assignmentChannelsRequested: selectedContractPartner?.assignmentChannels
            ? selectedContractPartner.assignmentChannels.filter(assignmentChannel => {
                if ((assignmentChannel === apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML
                        && !selectedContractPartner?.businessContactDetails?.email)
                    || (assignmentChannel === apmContractPartnerAssignmentChannelTypes.FAX
                        && !selectedContractPartner?.businessContactDetails?.faxNo)
                    || (assignmentChannel === apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE
                        && !selectedContractPartner?.businessContactDetails?.phoneNo)) {
                    return;
                }
                return config.IS_FALLBACK_SYSTEM
                    ? (assignmentChannel === apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML
                        || assignmentChannel === apmContractPartnerAssignmentChannelTypes.FAX)
                    : assignmentChannel;
            })
            : [],
        email: selectedContractPartner?.businessContactDetails?.email || '',
        phoneNo: selectedContractPartner?.businessContactDetails?.phoneNo || '',
        faxNo: selectedContractPartner?.businessContactDetails?.faxNo || '',
    });

    const downloadPDFButtonRef = useRef(null);
    const isManualACEPartnerDataValid = useRef(false);
    const isFormDataReady = useRef(false);
    const hasNecessaryChannels = !(formData.assignmentChannelsRequested.length === 0
        || (formData.assignmentChannelsRequested.length === 1
            && formData.assignmentChannelsRequested.includes(apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE))
    );
    const disallowedSymbols = ['Tab', ' '];

    const handleRedirectToContractPartnerScreen = event => {
        event.preventDefault();
        event.stopPropagation();
        const {pathname} = resolveRoute(
            routePaths.CONTRACT_PARTNER,
            {contractPartnerId: selectedContractPartner.id},
        );
        window.open(`${pathname}?activeTab=${contractPartnerScreenTabs.SERVICES}`, '_blank');
    };

    const isSubmitButtonDisabled = () => {
        const isStatePending = serviceCase.persistenceState === persistenceStates.PENDING
            || serviceAssignment.persistenceState === persistenceStates.PENDING;
        let hasMandatoryFields = true;

        switch (serviceCase.caseType) {
            case efServiceCaseTypes.VEHICLE: {
                hasMandatoryFields = serviceCase.nonAceMember || (config.IS_FALLBACK_SYSTEM && !serviceCase.vehicle?.vehicleId && serviceCase.vehicle?.creationStatus === ampVehicleCreationStatusTypes.COMPLETED) // eslint-disable-line max-len
                    ? (serviceCase.vehicle
                        && serviceCase.vehicle?.model
                        && serviceCase.vehicle?.manufacturer
                        && serviceCase.vehicle?.licensePlateNumber
                        && hasDriverAndOwnerData
                        && serviceCase?.isCallbackPhoneValid
                        && hasServiceType
                    )
                    : (serviceCase?.vehicle?.vehicleId
                        && hasDriverAndOwnerData
                        && serviceCase?.isCallbackPhoneValid
                        && hasServiceType
                    );
                break;
            }

            case efServiceCaseTypes.PERSON: {
                if ([
                    efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                    efServiceAssignmentTypes.DEATH,
                    efServiceAssignmentTypes.MEDICINE_SHIPMENT,
                ].includes(initialServiceAssignment?.assignmentType)
                    && serviceCase?.transportationType === efTransportationTypes.PERSONAL_VEHICLE) {
                    hasMandatoryFields = !!serviceCase?.vehicle?.vehicleId;
                }

                if (initialServiceAssignment?.assignmentType === efServiceAssignmentTypes.SUBSTITUTE_DRIVER) {
                    hasMandatoryFields = !!serviceCase?.vehicle?.vehicleId;
                }
                break;
            }

            case efServiceCaseTypes.HOUSEHOLD: {
                // todo tbd for household cases
                break;
            }

            default: {
                // no-op;
                break;
            }
        }

        return isStatePending || !hasMandatoryFields || !hasNecessaryChannels;
    };

    const handleDownloadPDF = () => {
        submitCreatePDFForm(formData);
        downloadPDFButtonRef.current.blur();
    };

    const handleOnSaveArchive = () => {
        initiateSaveArchiveServiceAssignmentFlow({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
        });
    };

    const handleOnAddACEPartnerManually = () => {
        isManualACEPartnerDataValid.current = false;
        initiateUpdateACEPartnerFlow({
            // TODO: add payload
        });
    };

    const validateAssignmentChannels = (selectedChannels, channelValues) => {
        if (!selectedChannels.length) return;
        let hasError = false;

        selectedChannels.forEach(selectedChannel => {
            const selectedChannelSetup = assignmentChannelSetup[selectedChannel];
            if (!selectedChannelSetup?.validationMethod) return;

            const channelValue = channelValues[selectedChannelSetup.fieldName];
            if (!selectedChannelSetup.validationMethod(channelValue)) {
                setErrorMessages(prevState => ({
                    ...prevState,
                    [apmContractPartnerAssignmentChannelTypes[selectedChannel]]: translateTab(`error_message.${selectedChannel.toLowerCase()}`),
                }));

                hasError = true;
            } else if (errorMessages[apmContractPartnerAssignmentChannelTypes[selectedChannel]] !== '') setErrorMessages(
                prevState => ({
                    ...prevState,
                    [apmContractPartnerAssignmentChannelTypes[selectedChannel]]: '',
                }),
            );
        });

        return hasError;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;
        const {assignmentChannelsRequested, ...channelValues} = formValues;

        if (!assignmentChannelsRequested?.length) return;

        if (validateAssignmentChannels(assignmentChannelsRequested, channelValues)) return;

        submitContractPartnerAssignmentChannels(formValues);
    };

    const handleOnChange = formValues => {
        if (isFormDataReady.current) {
            isFormDataReady.current = false;
            return;
        }
        setFormData(formValues);
    };

    const togglePhoneList = () => {
        setIsPhoneToggleOn(!isPhoneToggleOn);
    };

    const handleTabChange = useCallback(() => {
        if (selectedContractPartner?.id) {
            initiateUpdateSAVAssignmentChannels({
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                serviceCaseId: serviceCase.id,
                serviceAssignmentData: {
                    acePartner: {
                        assignmentChannels: formData['assignmentChannelsRequested'] || [],
                        businessContactDetails: {
                            email: formData['email'],
                            faxNo: formData['faxNo'],
                        },
                        contactDetails: null,
                    },
                },
            });
        }
    }, [
        initiateUpdateSAVAssignmentChannels,
        formData,
        serviceAssignment.lineNo,
        serviceCase.id,
        selectedContractPartner,
    ]);

    useEffect(() => {
        if (serviceAssignment?.acePartner?.isManuallyAdded && !isManualACEPartnerDataValid.current
            && !(location.state?.isModalOpen && location.state?.modalId === modalIds.ACE_PARTNER_DATA)
        ) {
            isManualACEPartnerDataValid.current = true;
            isFormDataReady.current = true;
            setFormData({
                assignmentChannelsRequested: serviceAssignment.acePartner?.assignmentChannels
                    ? serviceAssignment.acePartner.assignmentChannels.filter(assignmentChannel => {
                        if ((assignmentChannel === apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML
                                && !serviceAssignment.acePartner?.businessContactDetails?.email)
                            || (assignmentChannel === apmContractPartnerAssignmentChannelTypes.FAX
                                && !serviceAssignment.acePartner?.businessContactDetails?.faxNo)
                            || (assignmentChannel === apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE
                                && !serviceAssignment.acePartner?.businessContactDetails?.phoneNo)) {
                            return;
                        }
                        return config.IS_FALLBACK_SYSTEM
                            ? (assignmentChannel === apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML
                                || assignmentChannel === apmContractPartnerAssignmentChannelTypes.FAX)
                            : assignmentChannel;
                    })
                    : [],
                email: serviceAssignment.acePartner?.businessContactDetails?.email || '',
                phoneNo: serviceAssignment.acePartner?.businessContactDetails?.phoneNo || '',
                faxNo: serviceAssignment.acePartner?.businessContactDetails?.faxNo || '',
            });
        }
    }, [serviceAssignment.acePartner, location]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!selectedContractPartner?.id) {
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_CHANNELS_UPDATE_FLOW,
            });
            return;
        }

        handleTabChange();
    }, [selectedContractPartner, handleTabChange]);

    const isServiceAssignmentNew = serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.NEW;

    // pre-sort emergency contacts by id and put 24/7 contact first
    const emergencyContacts = selectedContractPartner?.emergencyContacts
        ? selectedContractPartner.emergencyContacts.slice().sort((emergencyContactA, emergencyContactB) => {
            if (emergencyContactA.is24h7Emergency) return -1;
            return emergencyContactA.id && emergencyContactB.id
                ? parseInt(emergencyContactA.id, 10) - parseInt(emergencyContactB.id, 10) : 0;
        }) : [];

    return (
        <Panel
            title={translateTab('panel_title.contract_partner')}
            actions={!!serviceAssignment?.acePartner?.isManuallyAdded && (
                <InteractiveIcon
                    icon={editIcon}
                    className={cx('ace-c-interactive-icon--reverse')}
                    onClick={() => handleOnAddACEPartnerManually()}
                    isDisabled={serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.NEW}
                />
            )}
        >
            <div className={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}>
                <Form
                    name="assignmentChannelsRequestedForm"
                    onSubmit={handleOnSubmit}
                    onChange={handleOnChange}
                >
                    {formValues => {
                        const isDriverAppChannelSelected = formValues['assignmentChannelsRequested']
                            && formValues['assignmentChannelsRequested']
                                .includes(apmContractPartnerAssignmentChannelTypes.DRIVER_APP);
                        const isEmailChannelSelected = formValues['assignmentChannelsRequested']
                            && formValues['assignmentChannelsRequested']
                                .includes(apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML);
                        const isFaxChannelSelected = formValues['assignmentChannelsRequested']
                            && formValues['assignmentChannelsRequested']
                                .includes(apmContractPartnerAssignmentChannelTypes.FAX);

                        return (
                            <Fragment>
                                {selectedContractPartner?.name ? (
                                    <div>
                                        <div className={cx('global!ace-u-grid')}>
                                            <div
                                                className={cx([
                                                    'global!ace-u-grid-column--span-12',
                                                    'global!ace-u-margin--bottom-24',
                                                ])}
                                            >
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-flex-start',
                                                        'global!ace-u-flex--justify-space-between',
                                                    ])}
                                                >
                                                    <p className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-margin--right-8'])}>
                                                        {selectedContractPartner ? selectedContractPartner.name : ''}
                                                    </p>
                                                    {!selectedContractPartner?.isManuallyAdded && (
                                                        <span
                                                            className={cx('global!ace-u-cursor--pointer')}
                                                            onClick={handleRedirectToContractPartnerScreen}
                                                        >
                                                            <Icon
                                                                icon={linkIcon}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                <p className={cx('global!ace-u-typography--variant-body')}>
                                                    {/* As per https://computerrock.atlassian.net/browse/ACEMS-1422,
                                                      * do NOT use 'acePartner.location.formattedAddress' field
                                                      */}
                                                    {selectedContractPartner?.location
                                                        ? [
                                                            selectedContractPartner.location.street,
                                                            [
                                                                selectedContractPartner.location.postCode,
                                                                selectedContractPartner.location.city,
                                                            ].filter(value => !!value).join(' '),
                                                        ].filter(value => !!value).join(', ')
                                                        : ''}
                                                </p>
                                                {emergencyContacts.length > 0 ? (
                                                    <Fragment>
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-typography--variant-caption',
                                                                'global!ace-u-margin--4-0',
                                                                'global!ace-u-flex',
                                                                'global!ace-u-flex--align-center',
                                                            ])}
                                                            title={translateTab(emergencyContacts[0].is24h7Emergency
                                                                ? 'text.emergency_contact_24_7'
                                                                : 'text.emergency_contact', {
                                                                type: emergencyContacts[0].type || '-',
                                                                name: emergencyContacts[0].name || '-',
                                                            })}
                                                        >
                                                            <span>
                                                                {translateTab(emergencyContacts[0].is24h7Emergency
                                                                    ? 'text.emergency_contact_24_7'
                                                                    : 'text.emergency_contact', {
                                                                    type: emergencyContacts[0].type || '-',
                                                                    name: emergencyContacts[0].name || '-',
                                                                })}&nbsp;
                                                            </span>
                                                            <a
                                                                href={`tel:${emergencyContacts[0].phoneNo}`}
                                                                className={cx([
                                                                    'global!ace-u-typography--variant-caption',
                                                                    'global!ace-u-typography--color-highlighted',
                                                                ])}
                                                            >
                                                                {emergencyContacts[0].phoneNo}
                                                            </a>
                                                            {emergencyContacts.length > 1 && (
                                                                <Icon
                                                                    className={cx([
                                                                        'ace-c-icon--color-highlight',
                                                                        'global!ace-u-margin--left-8',
                                                                    ])}
                                                                    icon={!isPhoneToggleOn
                                                                        ? arrowDownIcon : arrowUpIcon}
                                                                    onClick={togglePhoneList}
                                                                />
                                                            )}
                                                        </div>
                                                        {isPhoneToggleOn
                                                            && emergencyContacts.length > 1
                                                            && emergencyContacts.map((emergencyContact, index) => {
                                                                if (index === 0) return null;

                                                                return (
                                                                    <p
                                                                        key={`${emergencyContact.phoneNo}-${index}`}
                                                                        className={cx([
                                                                            'global!ace-u-typography--variant-caption',
                                                                            'global!ace-u-margin--top-4',
                                                                            'global!ace-u-margin--bottom-4',
                                                                        ])}
                                                                        title={translateTab('text.emergency_contact', {
                                                                            type: emergencyContact.type || '-',
                                                                            name: emergencyContact.name || '-',
                                                                        })}
                                                                    >
                                                                        {translateTab('text.emergency_contact', {
                                                                            type: emergencyContact.type || '-',
                                                                            name: emergencyContact.name || '-',
                                                                        })}
                                                                        &nbsp;
                                                                        <a
                                                                            href={`tel:${emergencyContact.phoneNo}`}
                                                                            className={cx([
                                                                                'global!ace-u-typography--variant-caption',
                                                                                'global!ace-u-typography--color-highlighted',
                                                                            ])}
                                                                        >
                                                                            {emergencyContact.phoneNo}
                                                                        </a>
                                                                    </p>
                                                                );
                                                            })}
                                                    </Fragment>
                                                ) : (
                                                    <div
                                                        className={cx([
                                                            'global!ace-u-typography--variant-caption',
                                                            'global!ace-u-margin--4-0',
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                        ])}
                                                    >
                                                        {translateTab('text.no_emergency_contacts')}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--bottom-24',
                                        ])}
                                    >
                                        {translateTab('text.no_service_provider_selected')}
                                    </p>
                                )}
                                {!hasNecessaryChannels && (
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--bottom-24',
                                        ])}
                                    >
                                        {translateTab('text.necessary_channels_not_selected')}
                                    </p>
                                )}
                                {/* eslint-disable max-len */}
                                {selectedContractPartner?.id
                                || (!selectedContractPartner?.id && selectedContractPartner?.isManuallyAdded) ? (
                                    <Fragment>
                                        <CheckboxGroup
                                            name="assignmentChannelsRequested"
                                            value={formData.assignmentChannelsRequested}
                                        >
                                            <div
                                                className={cx([
                                                    'global!ace-u-grid',
                                                    'global!ace-u-margin--bottom-24',
                                                ])}
                                            >
                                                {
                                                    selectedContractPartner
                                                    && selectedContractPartner.assignmentChannels?.includes(apmContractPartnerAssignmentChannelTypes.DRIVER_APP)
                                                    && !selectedContractPartner.isManuallyAdded
                                                    && !config.IS_FALLBACK_SYSTEM ? (
                                                        <Fragment>
                                                            <Checkbox
                                                                key="assignmentChannelDriverApp"
                                                                name="assignmentChannelDriverApp"
                                                                value={apmContractPartnerAssignmentChannelTypes.DRIVER_APP}
                                                                className={cx('global!ace-u-grid-column-span-1')}
                                                                isDisabled={!isServiceAssignmentNew}
                                                            />
                                                            <div
                                                                className={cx([
                                                                    'global!ace-u-grid-column--span-11',
                                                                    'global!ace-u-flex',
                                                                    'global!ace-u-flex--direction-column',
                                                                ])}
                                                            >
                                                                {translateAssignmentChannel(snakeCase(apmContractPartnerAssignmentChannelTypes.DRIVER_APP))}
                                                            </div>
                                                        </Fragment>
                                                        ) : null
                                                }
                                                <Checkbox
                                                    key="assignmentChannelEmailLink"
                                                    name="assignmentChannelEmailLink"
                                                    value={apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML}
                                                    className={cx('global!ace-u-grid-column-span-1')}
                                                    isDisabled={isDriverAppChannelSelected
                                                        || !isServiceAssignmentNew}
                                                />
                                                <div
                                                    className={cx([
                                                        'global!ace-u-grid-column--span-11',
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--direction-column',
                                                    ])}
                                                >
                                                    <InputField
                                                        name="email"
                                                        label={translateAssignmentChannel(snakeCase(apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML))}
                                                        isDisabled={isDriverAppChannelSelected
                                                            || !isServiceAssignmentNew
                                                            || !isEmailChannelSelected}
                                                        value={formData.email || ''}
                                                        errors={errorMessages[apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]
                                                            ? [errorMessages[apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]]
                                                            : []}
                                                        disallowedSymbols={disallowedSymbols}
                                                    />
                                                </div>
                                                {!config.IS_FALLBACK_SYSTEM && (
                                                    <Fragment>
                                                        <Checkbox
                                                            key="assignmentChannelManualPhone"
                                                            name="assignmentChannelManualPhone"
                                                            value={apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE}
                                                            className={cx('global!ace-u-grid-column-span-1')}
                                                            isDisabled={true}
                                                        />
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-grid-column--span-11',
                                                                'global!ace-u-flex',
                                                                'global!ace-u-flex--direction-column',
                                                            ])}
                                                        >
                                                            <InputField
                                                                name="phoneNo"
                                                                label={translateAssignmentChannel(snakeCase(apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE))}
                                                                isDisabled={true}
                                                                value={formData.phoneNo || ''}
                                                                errors={errorMessages[apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE]
                                                                    ? [errorMessages[apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE]]
                                                                    : []}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                )}
                                                <Checkbox
                                                    key="assignmentChannelFax"
                                                    name="assignmentChannelFax"
                                                    value={apmContractPartnerAssignmentChannelTypes.FAX}
                                                    className={cx('global!ace-u-grid-column-span-1')}
                                                    isDisabled={isDriverAppChannelSelected
                                                        || !isServiceAssignmentNew}
                                                />
                                                <div
                                                    className={cx([
                                                        'global!ace-u-grid-column--span-11',
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--direction-column',
                                                    ])}
                                                >
                                                    <InputField
                                                        name="faxNo"
                                                        label={translateAssignmentChannel(snakeCase(apmContractPartnerAssignmentChannelTypes.FAX))}
                                                        isDisabled={isDriverAppChannelSelected
                                                            || !isServiceAssignmentNew
                                                            || !isFaxChannelSelected}
                                                        value={formData.faxNo || ''}
                                                        errors={errorMessages[apmContractPartnerAssignmentChannelTypes.FAX]
                                                            ? [errorMessages[apmContractPartnerAssignmentChannelTypes.FAX]]
                                                            : []}
                                                        disallowedSymbols={disallowedSymbols}
                                                    />
                                                </div>
                                            </div>
                                        </CheckboxGroup>
                                        {serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.NEW ? (
                                            <Fragment>
                                                <ButtonPrimary
                                                    name="button"
                                                    isDisabled={isSubmitButtonDisabled()}
                                                    type="submit"
                                                    className={cx('global!ace-u-full-width')}
                                                >
                                                    {translateTab('button_label.assign')}
                                                </ButtonPrimary>
                                                {!selectedContractPartner?.isManuallyAdded && (
                                                    <Button
                                                        className={cx([
                                                            'global!ace-u-margin--top-24',
                                                            'global!ace-u-full-width',
                                                        ])}
                                                        onClick={handleOnAddACEPartnerManually}
                                                    >
                                                        {translateTab('button_label.enter_another_partner')}
                                                    </Button>
                                                )}
                                                <Button
                                                    className={cx([
                                                        'global!ace-u-margin--top-24',
                                                        'global!ace-u-full-width',
                                                    ])}
                                                    onClick={handleOnSaveArchive}
                                                >
                                                    {translateTab('button_label.save_archive')}
                                                </Button>
                                            </Fragment>
                                        ) : (
                                            <ButtonPrimary
                                                onClick={initiateServiceAssignmentStatusUpdateFlow}
                                                className={cx('global!ace-u-full-width')}
                                            >
                                                {translateTab('button_label.update_status')}
                                            </ButtonPrimary>
                                        )}
                                    </Fragment>
                                    ) : (
                                        <Button
                                            className={cx([
                                                'global!ace-u-margin--top-24',
                                                'global!ace-u-full-width',
                                            ])}
                                            onClick={handleOnAddACEPartnerManually}
                                        >
                                            {translateTab('button_label.enter_another_partner')}
                                        </Button>
                                    )}
                                {/* eslint-enable max-len */}
                            </Fragment>
                        );
                    }}
                </Form>
                <Button
                    ref={downloadPDFButtonRef}
                    className={cx('global!ace-u-margin--top-24')}
                    onClick={handleDownloadPDF}
                >
                    {translateTab('button_label.download_pdf')}
                </Button>
                {serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.NEW && (
                    <Button
                        className={cx('global!ace-u-margin--top-24')}
                        onClick={() => initiateResendServiceAssignmentFlow({
                            attachmentsToSendWithAssignment,
                        })}
                    >
                        {translateTab('button_label.send_again')}
                    </Button>
                )}
            </div>
        </Panel>
    );
};

CommissioningContractPartnerPanel.propTypes = {
    initiateServiceAssignmentStatusUpdateFlow: PropTypes.func.isRequired,
    initiateSaveArchiveServiceAssignmentFlow: PropTypes.func.isRequired,
    submitCreatePDFForm: PropTypes.func.isRequired,
    submitContractPartnerAssignmentChannels: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    initialServiceAssignment: PropTypes.object,
    initiateUpdateSAVAssignmentChannels: PropTypes.func.isRequired,
    initiateResendServiceAssignmentFlow: PropTypes.func.isRequired,
    initiateUpdateACEPartnerFlow: PropTypes.func.isRequired,
    attachmentsToSendWithAssignment: PropTypes.array,
    location: PropTypes.object,
};

CommissioningContractPartnerPanel.defaultProps = {
    serviceCase: {},
    serviceAssignment: {},
    initialServiceAssignment: {},
    attachmentsToSendWithAssignment: null,
    location: {},
};

const mapStateToProps = (state, props) => {
    const getServiceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const getInitialAssignment = serviceCaseSelectors.createInitialServiceAssignmentSelector();

    return {
        serviceCase: getServiceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        initialServiceAssignment: getInitialAssignment(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateServiceAssignmentStatusUpdateFlow: () => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_STATUS_UPDATE_FLOW,
    }),
    initiateSaveArchiveServiceAssignmentFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
    initiateUpdateSAVAssignmentChannels: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_CHANNELS_UPDATE_FLOW,
        payload,
    }),
    initiateResendServiceAssignmentFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_RESEND_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
    initiateUpdateACEPartnerFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_UPDATE_ACE_PARTNER,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissioningContractPartnerPanel));
